

const initialState = {
    SelectedProduct: null,
    PriceDetails: null,
    UpgradePriceInfo: null,
    OrderConfirm: null,
    OrderProcessingTokenData: null,
    OrderPending: null,
    OrderEvaluated: null,
    SelectedEmail: null,
    OrderPlacementDetails: null
}

function OrderReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_SELECTED_EMAIL':
            return {
                ...state,
                SelectedEmail: action.data
            }
        case 'SET_SELECTED_PRODUCT':
            return {
                ...state,
                SelectedProduct: action.data
            }
        case 'SET_ORDER_PRICE_DETAILS':
            return {
                ...state,
                PriceDetails: action.data
            }
        case 'SET_ORDER_CONFIRM':
            return {
                ...state,
                OrderConfirm: action.data
            }
        case 'SET_ORDER_PROCESSING_TOKEN':
            return {
                ...state,
                OrderProcessingTokenData: action.data
            }
        case 'SET_ORDER_PENDING':
            return {
                ...state,
                OrderPending: action.data
            }
        case 'SET_ORDER_EVALUATED':
            return {
                ...state,
                OrderEvaluated: action.data
            }
        case 'SET_UPGRADE_PRICE_INFO':
            return {
                ...state,
                UpgradePriceInfo: action.data
            }
        case 'SET_ORDER_PLACEMENT_DETAILS':
            return {
                ...state,
                OrderPlacementDetails: action.data
            }
        case 'RESET_ORDER_REDUCER':
            return {...initialState}
        default:
            return state
    }
}

export default OrderReducer;