/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import wrapWithProvider from "./wrap-with-provider"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const wrapRootElement = wrapWithProvider
require("./src/styles/bootstrap.min.css");
require("./src/styles/font-awesome.min.css")
require('bootstrap/dist/js/bootstrap.min.js')
require('aos/dist/aos.css')
require('./src/styles/global.css')

export const onClientEntry = () => {  
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (typeof window.IntersectionObserver === `undefined`) {
      require(`intersection-observer`)
      console.log(`# IntersectionObserver is polyfilled!`)
    }
}