// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-account-new-plan-index-js": () => import("./../src/pages/account/new-plan/index.js" /* webpackChunkName: "component---src-pages-account-new-plan-index-js" */),
  "component---src-pages-account-order-details-js": () => import("./../src/pages/account/order/details.js" /* webpackChunkName: "component---src-pages-account-order-details-js" */),
  "component---src-pages-account-order-history-js": () => import("./../src/pages/account/order-history.js" /* webpackChunkName: "component---src-pages-account-order-history-js" */),
  "component---src-pages-account-order-payment-index-js": () => import("./../src/pages/account/order/payment/index.js" /* webpackChunkName: "component---src-pages-account-order-payment-index-js" */),
  "component---src-pages-account-order-payment-status-js": () => import("./../src/pages/account/order/payment/status.js" /* webpackChunkName: "component---src-pages-account-order-payment-status-js" */),
  "component---src-pages-account-order-product-js": () => import("./../src/pages/account/order/product.js" /* webpackChunkName: "component---src-pages-account-order-product-js" */),
  "component---src-pages-account-order-upgrade-js": () => import("./../src/pages/account/order/upgrade.js" /* webpackChunkName: "component---src-pages-account-order-upgrade-js" */),
  "component---src-pages-account-plan-js": () => import("./../src/pages/account/plan.js" /* webpackChunkName: "component---src-pages-account-plan-js" */),
  "component---src-pages-account-profile-js": () => import("./../src/pages/account/profile.js" /* webpackChunkName: "component---src-pages-account-profile-js" */),
  "component---src-pages-account-referral-js": () => import("./../src/pages/account/referral.js" /* webpackChunkName: "component---src-pages-account-referral-js" */),
  "component---src-pages-account-users-js": () => import("./../src/pages/account/users.js" /* webpackChunkName: "component---src-pages-account-users-js" */),
  "component---src-pages-buy-index-js": () => import("./../src/pages/buy/index.js" /* webpackChunkName: "component---src-pages-buy-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-demo-index-js": () => import("./../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-demo-thank-you-js": () => import("./../src/pages/demo/thank-you.js" /* webpackChunkName: "component---src-pages-demo-thank-you-js" */),
  "component---src-pages-disclaimer-js": () => import("./../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-features-js": () => import("./../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-import-js": () => import("./../src/pages/import.js" /* webpackChunkName: "component---src-pages-import-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-js": () => import("./../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mfcentral-cas-api-terms-js": () => import("./../src/pages/mfcentral-cas-api-terms.js" /* webpackChunkName: "component---src-pages-mfcentral-cas-api-terms-js" */),
  "component---src-pages-mprofit-10-beta-launch-js": () => import("./../src/pages/mprofit-10-beta-launch.js" /* webpackChunkName: "component---src-pages-mprofit-10-beta-launch-js" */),
  "component---src-pages-pricing-index-js": () => import("./../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-pricing-investors-js": () => import("./../src/pages/pricing/investors.js" /* webpackChunkName: "component---src-pages-pricing-investors-js" */),
  "component---src-pages-pricing-wealth-js": () => import("./../src/pages/pricing/wealth.js" /* webpackChunkName: "component---src-pages-pricing-wealth-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-refer-js": () => import("./../src/pages/refer.js" /* webpackChunkName: "component---src-pages-refer-js" */),
  "component---src-pages-referral-terms-js": () => import("./../src/pages/referral-terms.js" /* webpackChunkName: "component---src-pages-referral-terms-js" */),
  "component---src-pages-refund-policy-js": () => import("./../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-request-pricing-index-js": () => import("./../src/pages/request-pricing/index.js" /* webpackChunkName: "component---src-pages-request-pricing-index-js" */),
  "component---src-pages-request-pricing-thank-you-js": () => import("./../src/pages/request-pricing/thank-you.js" /* webpackChunkName: "component---src-pages-request-pricing-thank-you-js" */),
  "component---src-pages-sign-up-index-js": () => import("./../src/pages/sign-up/index.js" /* webpackChunkName: "component---src-pages-sign-up-index-js" */),
  "component---src-pages-sign-up-investors-index-js": () => import("./../src/pages/sign-up/investors/index.js" /* webpackChunkName: "component---src-pages-sign-up-investors-index-js" */),
  "component---src-pages-sign-up-investors-success-js": () => import("./../src/pages/sign-up/investors/success.js" /* webpackChunkName: "component---src-pages-sign-up-investors-success-js" */),
  "component---src-pages-sign-up-success-advisor-js": () => import("./../src/pages/sign-up/success-advisor.js" /* webpackChunkName: "component---src-pages-sign-up-success-advisor-js" */),
  "component---src-pages-sign-up-success-investor-js": () => import("./../src/pages/sign-up/success-investor.js" /* webpackChunkName: "component---src-pages-sign-up-success-investor-js" */),
  "component---src-pages-sign-up-success-pro-js": () => import("./../src/pages/sign-up/success-pro.js" /* webpackChunkName: "component---src-pages-sign-up-success-pro-js" */),
  "component---src-pages-sign-up-wealth-index-js": () => import("./../src/pages/sign-up/wealth/index.js" /* webpackChunkName: "component---src-pages-sign-up-wealth-index-js" */),
  "component---src-pages-sign-up-wealth-success-js": () => import("./../src/pages/sign-up/wealth/success.js" /* webpackChunkName: "component---src-pages-sign-up-wealth-success-js" */),
  "component---src-pages-subscription-prefs-js": () => import("./../src/pages/subscription-prefs.js" /* webpackChunkName: "component---src-pages-subscription-prefs-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-third-party-licenses-js": () => import("./../src/pages/third-party-licenses.js" /* webpackChunkName: "component---src-pages-third-party-licenses-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */),
  "component---src-pages-version-history-js": () => import("./../src/pages/version-history.js" /* webpackChunkName: "component---src-pages-version-history-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tag-list-template-js": () => import("./../src/templates/blog-tag-list-template.js" /* webpackChunkName: "component---src-templates-blog-tag-list-template-js" */),
  "component---src-templates-client-type-template-js": () => import("./../src/templates/client-type-template.js" /* webpackChunkName: "component---src-templates-client-type-template-js" */),
  "component---src-templates-help-category-template-js": () => import("./../src/templates/help-category-template.js" /* webpackChunkName: "component---src-templates-help-category-template-js" */),
  "component---src-templates-help-topic-template-js": () => import("./../src/templates/help-topic-template.js" /* webpackChunkName: "component---src-templates-help-topic-template-js" */),
  "component---src-templates-reviews-list-template-js": () => import("./../src/templates/reviews-list-template.js" /* webpackChunkName: "component---src-templates-reviews-list-template-js" */)
}

