import { createStore as reduxCreateStore } from "redux"
import { combineReducers } from 'redux';
import OrderReducer from "./reducers/orderReducer";

const AppReducer = combineReducers({
    order: OrderReducer
});

// const reducer = (state, action) => {
//   if (action.type === `INCREMENT`) {
//     return Object.assign({}, state, {
//       count: state.count + 1,
//     })
//   }
//   return state
// }

const RootReducer = (state, action) => {
    switch (action.type) {
      default:
        return AppReducer(state, action)
    }
}

const createStore = () => reduxCreateStore(RootReducer)
export default createStore